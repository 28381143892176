import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <br />
    <p><a parentName="p" {...{
        "href": "https://alainro.github.io/deckgl.github.io/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.963190184049076%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAetaDMf/xAAXEAEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAEBAAEFAtiuP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAAAAgMf/aAAgBAQAGPwIq/wD/xAAYEAEBAQEBAAAAAAAAAAAAAAABABEhEP/aAAgBAQABPyEJN4YgCGGeDgC//9oADAMBAAIAAwAAABDTz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAICAgMAAAAAAAAAAAAAAAERACExQWFxgf/aAAgBAQABPxApZNaizB5N6gJEE+OZAT3BYXEoik7uf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "societes",
            "title": "societes",
            "src": "/static/69a3b9454b1934e403b2531d999128d5/6aca1/societes.jpg",
            "srcSet": ["/static/69a3b9454b1934e403b2531d999128d5/d2f63/societes.jpg 163w", "/static/69a3b9454b1934e403b2531d999128d5/c989d/societes.jpg 325w", "/static/69a3b9454b1934e403b2531d999128d5/6aca1/societes.jpg 650w", "/static/69a3b9454b1934e403b2531d999128d5/7c09c/societes.jpg 975w", "/static/69a3b9454b1934e403b2531d999128d5/68a11/societes.jpg 1246w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <br />
    <p>{`Parmi les lois naturelles de la « science » de la visualisation de données, dans le classement de tête avec les injonctions sur les camemberts à tranches nombreuses et les axes qui doivent commencer à zéro, il y a: `}</p>
    <center>
      <blockquote>
        <p parentName="blockquote"><strong parentName="p">{`NE PAS UTILISER DE DIAGRAMMES EN 3D`}</strong>{` `}</p>
      </blockquote>
    </center>
    <p>{`C’est un fait. L’œil arrive à jauger sans effort les positions. Les longueurs sont aussi utilisables, mais les surfaces sont pratiquement impossibles à comparer. `}</p>
    <p>{`Alors les volumes!!`}</p>
    <p>{`La troisième dimension avec ses projections mathématiques, ses sources lumineuses et ombres portées, ... Seuls les débutants complets ignorent que la perspective « déforme »  les données.`}</p>
    <p>{`Et si par chance on trouve un joli diagramme en 3D qui exprime de la meilleure des manières un jeu de données, il convient de se méfier de sa robustesse: « Est il toujours aussi adéquat quand les données changent ? »`}</p>
    <p>{`Parmi les critiques légitimes des représentations 3D:`}</p>
    <ul>
      <li parentName="ul">{`données cachées par d’autres`}</li>
      <li parentName="ul">{`appréciation très subjective et approximative des grandeurs `}</li>
      <li parentName="ul">{`comparaison des données rendues difficiles par la perspective`}</li>
      <li parentName="ul">{`impression faussée (exagérée ou sous-estimée) des évolutions`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "623px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "81.59509202453987%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHqFB2IJ//EABkQAQADAQEAAAAAAAAAAAAAAAECAxESAP/aAAgBAQABBQKxsGyzER9vc7Q6jEif/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHhAAAgEDBQAAAAAAAAAAAAAAAAECERIhEBMxQWH/2gAIAQEABj8CxwJXRrpKDhjpiW1d6UR//8QAGxABAAICAwAAAAAAAAAAAAAAAQARITFBUfD/2gAIAQEAAT8hKtaezMFC2JcEsYtySwuOtxTiaQnU/9oADAMBAAIAAwAAABBTz//EABcRAQADAAAAAAAAAAAAAAAAAAEQETH/2gAIAQMBAT8QStj/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8Q0Z//xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhMUFRYXGh/9oACAEBAAE/EE/PIoD7bj5KKzRBJ5Uq7TULe5UQwZt73UUiEUNOvJUx30z+s//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "3D",
          "title": "3D",
          "src": "/static/e084ac45354e0888084f1e39bced43ff/50112/3D.jpg",
          "srcSet": ["/static/e084ac45354e0888084f1e39bced43ff/d2f63/3D.jpg 163w", "/static/e084ac45354e0888084f1e39bced43ff/c989d/3D.jpg 325w", "/static/e084ac45354e0888084f1e39bced43ff/50112/3D.jpg 623w"],
          "sizes": "(max-width: 623px) 100vw, 623px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`WebGL est la norme en passe de dégager la solution à la diffusion de la 3D sur le Web de monsieur et madame tout le monde au travers des navigateurs standards sur ordinateurs, tablettes et téléphones. Le bénéfice technique majeur est que l'affichage du contenu WebGL (qui n’est d’ailleurs pas forcement en 3D) s'effectue grâce à la carte graphique (GPU) sans intervention du processeur (CPU). C'est ce qui rend possible la visualisation/ manipulation géométrique de grandes quantités de données via un simple navigateur.`}</em></p>
    </blockquote>
    <hr></hr>
    <br />
    <p>{`J’ai récemment eu une révélation sur ce sujet lors une démonstration d'une librairie de visualisation créée par Uber qui combine React et la 3D avec WebGL: `}<a parentName="p" {...{
        "href": "https://uber.github.io/deck.gl/#/"
      }}>{`deck.gl`}</a></p>
    <br />
    <p><a parentName="p" {...{
        "href": "https://uber.github.io/deck.gl/#/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.668711656441715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHlSypKJ//EABcQAQADAAAAAAAAAAAAAAAAAAEAESD/2gAIAQEAAQUCoyE//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/Aqv/xAAaEAACAgMAAAAAAAAAAAAAAAAAARARIVFx/9oACAEBAAE/IdGfBopzYj//2gAMAwEAAgADAAAAEBjf/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QGf/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EIj/xAAbEAEAAwEAAwAAAAAAAAAAAAABABExIRBRgf/aAAgBAQABPxArOiMWd9SratzsHyn7LfBWK7P/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "deck gl",
            "title": "deck gl",
            "src": "/static/73c16c9e409f7a575cb654eed7a9ce9d/6aca1/deck.gl.jpg",
            "srcSet": ["/static/73c16c9e409f7a575cb654eed7a9ce9d/d2f63/deck.gl.jpg 163w", "/static/73c16c9e409f7a575cb654eed7a9ce9d/c989d/deck.gl.jpg 325w", "/static/73c16c9e409f7a575cb654eed7a9ce9d/6aca1/deck.gl.jpg 650w", "/static/73c16c9e409f7a575cb654eed7a9ce9d/0ede0/deck.gl.jpg 945w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <br />
    <h3>{`3D statique ou qui bouge ?`}</h3>
    <p>{`J’ai appliqué et même enseigné ce dogme sans aucun état d’âme. Mais en y réfléchissant plus profondément, j’ai l’impression que beaucoup des défauts présumés de la 3D s’appliquent en fait aux `}<strong parentName="p">{`images statiques`}</strong>{` qui ne `}<strong parentName="p">{`peuvent pas bouger`}</strong>{`, dont on ne peut pas faire le tour pour `}<strong parentName="p">{`mieux voir`}</strong>{`. `}</p>
    <p>{`J’ai expérimenté deck.gl sur les données INPI des sociétés françaises et leur localisation par code postal. L’impression à l’usage de la 3D sur une carte est totalement légitime et naturelle. Faire `}<strong parentName="p">{`tourner le point de vue`}</strong>{` pour voir ce qui est derrière est absolument intuitif et efficace.
S’il est possible de modifier la perspective à volonté, les objections des données non-visibles tombent naturellement. Y compris la comparaison `}</p>
    <p>{`Mieux encore la troisième dimension permet de focaliser sur une zone précise (zoom) tout en choisissant le contexte environnant (rotation et point de vue aérien ou à hauteur de la donnée).`}</p>
    <br />
    <center>
      <p><a parentName="p" {...{
          "href": "https://alainro.github.io/deckgl.github.io/"
        }}>{`Pour essayer cliquer ici`}</a></p>
    </center>
    <br />
    <p><em parentName="p">{`Noter qu'il s'agit d'une démonstration absolument pas optimisée qui nécessite de charger plus de 10Mo et inutilisable sur autre chose qu'un ordinateur de bureau patient. Une fois chargé webgl réalise sa magie impressionnante d'immersion 3D. `}</em></p>
    <p><strong parentName="p">{`La navigation dans la carte en 3D s'effectue comme sur la plupart des cartes, à la souris: molette pour le zoom, glisser et shift/maj souris pour faire pivoter la vue.`}</strong>{`  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      